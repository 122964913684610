import * as React from "react"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'

import Container from "../helpers/Container.js"
import ContainerWithBg from "../helpers/ContainerWithBg.js"

import Page from "../elements/Page.js"
import BlogPost from "../elements/BlogPost.js"
import Sidebar from "../section/Sidebar.js"
import { Pagination } from "../components/Pagination.js"

export default class CategoriesPage extends React.Component {

  render() {

    const { currentPage, categoryNumPages, title, nodeUri } = this.props.pageContext
    const { data } = this.props

    return (
      <Page>
        <Seo 
          title={data.wpCategory.seo.title}
          post={data.wpCategory.seo} 
        />
        <ContainerWithBg 
          classBgName="bg-pattern bg-pattern-gradient bg-image-blog-hero"
          className="container-md tac mh-27r ai-c jc-c dfc c-white p-r z1"
        >
          <h1 className="h1-egg mb-15r">{ title }</h1>
        </ContainerWithBg>
        <Container className="container-xg mt-4r mb-4r">
          <div className="container-fluid">

            <div className="row">
              <div className="col-lg-8">
                <div className="pe-lg-8">
                  <h2 className="c-purple h2 mb-3r">Notícias</h2>

                  <div className="row">
                    {
                      data.allWpPost.nodes.map(node => (
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4" key={node.id}>
                        <BlogPost 
                          tag={node.categories.nodes[0].name}
                          date={node.date}
                          toPath={node.link}
                          toPathTag={node.categories.nodes[0].link}
                          title={node.title}
                          excerpt={node.excerpt}
                          key={node.id}
                          imageSrc={(node.featuredImage) ? node.featuredImage.node : null}
                        />
                        </div>
                      ))
                    }
                  </div>
                  <Pagination
                    postsNumPages={categoryNumPages}
                    currentPage={currentPage}
                    base={nodeUri}
                  />
                  </div>
                </div>
                <div className="col-lg-4">
                  <Sidebar />
                </div>
              </div>
          </div>
        </Container>
      </Page>
    )
  }
}

export const blogListQuery = graphql`
query($skip: Int!, $limit: Int!, $categoryId: Int!) {
  wpCategory(databaseId: {eq: $categoryId}) {
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
    }
  }
  allWpPost(
    sort: {fields: [date], order: DESC}
    limit: $limit
    skip: $skip
    filter: {categories: {nodes: {elemMatch: {databaseId: {eq: $categoryId}}}}}
  ) {
    nodes {
      id
      title
      excerpt
      content
      link
      date(formatString: "D/M/y")
      categories {
        nodes {
          link
          name
        }
      }
      author {
        node {
          nicename
          firstName
          lastName
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
}
`